// routes
// import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
// components
// import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Menu',
    path: '/menu',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    children: [
      
      {
        subheader: 'Tasher Ghor',
        items: [
          // { title: 'Gallery', path: '/Gallery' },
          { title: 'Accommodations', path: '/Accommodations'},
          { title: 'Food Menu', path: '/FoodMenu' },
          { title: 'Reviews', path: '/Reviews' },
          { title: 'Contact us', path: '/Contact' },
          { title: 'FAQs', path: '/FAQs' },
          { title: 'Privacy Policy', path: '/PrivacyPolicy' },
          { title: 'Terms & Conditions', path: '/Terms&Conditions' },
          { title: 'Cancellation Policy', path: '/CancellationPolicy' },
          

        ],
      },
      {
        subheader: 'Important Links',
        items: [
          { title: 'Blog', path: '/Blog/' },
          { title: 'About Mousuni', path: '/Blog/mousuni-island-resort-hotel-camp-booking' },
          { title: 'Why Tasher Ghor?', path: '/Blog/why-tasher-ghor' },
          { title: 'How to Book', path: '/Blog/how-to-book' },
          { title: 'How to Reach', path: '/Blog/how-to-reach-mousuni-island' },
          { title: 'Train & Ferry Timing', path: '/Blog/train-ferry-timing' },
        ],
      },
      {
        subheader: 'Social Media Link',
        items: [
          { title: 'Instagram', path: 'https://www.instagram.com/mousuni.tasherghor' },
          { title: 'Facebook', path: 'https://www.facebook.com/mousuni.tasherghor' },
          { title: 'YouTube', path: 'https://www.youtube.com/@Tasherghor-Mousuni' },
          { title: 'Google', path: 'https://g.co/kgs/TdvLmng' },
          { title: 'Twitter', path: '' },


        ],
      },
      {
        subheader: 'Gallery',
        items: [{ title: 'Gallery', path: '/Gallery' }],
      },
    ],
  },
  // {
  //   title: 'Documentation',
  //   icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
  //   path: "PATH_DOCS",
  // },
];

export default menuConfig;
