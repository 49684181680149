import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// import { textAlign } from '@mui/system';
import { PATH_HOME } from './paths';



// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '',
      element: (
        // <AuthGuard>
        <MainLayout />
        // </AuthGuard>
      ),
      // Below All Childrens has Menu Bar in the Top 
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: PATH_HOME.dashboard, element: <HomePage /> },
        { path: '/', element: <HomePage /> },
        { path: '/Home', element: <HomePage /> },
        { path: '/Accommodations', element: <Accommodations /> },
        { path: '/FoodMenu', element: <FoodMenu />},
        { path: '/Contact', element: <Contact />},
        { path: '/Gallery', element: <Gallery />},
        { path: '/Reviews', element: <Reviews />},
        { path: '/Testimonials', element: <Testimonials />},
        { path: '/FAQs', element: <FAQs />},
        { path: '/PrivacyPolicy', element: <PrivacyPolicy />},
        { path: '/Terms&Conditions', element: <TermsConditions />},
        { path: '/CancellationPolicy', element: <CancellationPolicy />},
        { path: '/Blog/:title', element: <BlogPost />},
        { path: '/Blog', element: <BlogPosts />},
        

        
      ],
    },
   
    // Below All Elements do not have Menu Bar in the Top 
    { path: '500', element: <Page500 /> },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '/blank', element: <PageBlank />},
  


  ]);
}

// IMPORT COMPONENTS
// Main
const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
const Accommodations = Loadable(lazy(() => import('../pages/Accommodations')));
const FoodMenu = Loadable(lazy(() => import('../pages/FoodMenu')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Gallery = Loadable(lazy(() => import('../pages/Gallery')));
const Reviews = Loadable(lazy(() => import('../pages/Reviews')));
const Testimonials = Loadable(lazy(() => import('../pages/Testimonials')));
//const Blog = Loadable(lazy(() => import('../pages/Blog')));
const FAQs = Loadable(lazy(() => import('../pages/FAQs')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const TermsConditions = Loadable(lazy(() => import('../pages/Terms&Conditions')));
const CancellationPolicy = Loadable(lazy(() => import('../pages/CancellationPolicy')));



const BlogPosts = Loadable(lazy(() => import('../pages/Blog/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/Blog/BlogPost')));


const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const PageBlank = Loadable(lazy(() => import('../pages/Blank')));




// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));

// // Authentication
// const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));



