import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import Iconify from '../../components/Iconify';

const RootStyle = styled('div')(() => ({
  position: 'fixed',
  bottom: '20px',
  right: '5px',
  zIndex: 999,
  // '@media (max-width: 600px)': { // Adjust the max-width to target mobile devices
  //   bottom: '9rem', // Adjust the bottom padding value for mobile devices
  // },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '100px',
  right: '5px', // Adjust the right property to create a gap
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  zIndex: 998,
  transition: 'all 0.3s ease-in-out',
}));

const RoundIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: 'transparent',
  width: 60,
  height: 60,
  // '&:hover': {
  //   backgroundColor: 'transparent',
  // },
}));

const customIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  width: 60,
  height: 60,
  // '&:hover': {
  //   backgroundColor: 'transparent',
  // },
}));


const ContactUsbtn = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContactForm = () => {
    setIsOpen(!isOpen);
  };

  const actions = [
    { icon: 'ion:logo-whatsapp', action: sendWhatsapp },
    { icon: 'logos:messenger', action: sendMessenger },
    { icon: 'eva:phone-outline', action: startCalling },
    // Add more actions as needed
  ];

  function sendWhatsapp() {
    const phoneNumber = '919062336699';
    const message = encodeURIComponent("Hi, I would like to know more about Tasher Ghor");
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`, '_blank');
}


  function sendMessenger() {
    window.open('https://www.facebook.com/messages/t/mousuni.tasherghor', '_blank');
}


  function startCalling() {
    window.open('tel:9062336699');
  }

  return (
    <RootStyle>
      <RoundIconButton color="primary" aria-label="contact-us" onClick={toggleContactForm}>
        {isOpen ? (
          <Iconify icon="eva:close-outline" sx={{ color: 'red'}} width={40} height={40} /> // Close button
        ) : (
          // <Iconify icon="ion:chatbubble-ellipses-sharp" width={40} height={40} />
          // <Iconify icon="streamline:call-center-support-service-solid" sx={{ color: 'red'}} width={40} height={40} />
          <Iconify icon="flat-color-icons:online-support" sx={{ color: 'red'}} width={40} height={40} />
        )}
      </RoundIconButton>

      {isOpen && (
        <ContentStyle>
          {actions.map((action, index) => (
            <Paper key={index} elevation={3}>
              <RoundIconButton
                color="primary"
                onClick={action.action}
              >
                <Iconify icon={action.icon} width={30} height={30} />

              </RoundIconButton>
              
            </Paper>
          ))}
        </ContentStyle>
      )}
    </RootStyle>
  );
};

export default ContactUsbtn;