import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';


// @mui
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Box, IconButton, Tooltip, Button, AppBar, Toolbar, Container, TextField } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

import Iconify from '../../components/Iconify';
import useSettings from '../../hooks/useSettings';
import { NAVBAR, defaultSettings } from '../../config';
import { IconButtonAnimate, varFade } from './../../components/animate/';

import ToggleButton from '../../components/settings/ToggleButton'; // Import the ToggleButton component
// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------



export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'md');
  const isHome = pathname === '/';


  const { themeMode, themeDirection, themeColorPresets, themeStretch, themeLayout, onResetSetting } = useSettings();
  const [open, setOpen] = useState(false);


  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch;

  const varSidebar =
    themeDirection !== 'rtl'
      ? varFade({
          distance: NAVBAR.BASE_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inRight
      : varFade({
          distance: NAVBAR.BASE_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inLeft;

  useEffect(() => {
    if (open) {
     // document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);



  return (



    


    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Logo */}
          {isLight ? (
              <img 
                src="https://ik.imagekit.io/tasherghor/TasherGhor/Logo/Tasher%20Ghor%20logo_english_black.png?updatedAt=1727261479807" 
                alt="Tasher Ghor Logo Black"
                height={50} 
                width={50} 
                sx={{ mr: 1 }}
              />
            ) : (
              <img 
                src="https://ik.imagekit.io/tasherghor/TasherGhor/Logo/Tasher%20Ghor%20logo_english_white.png?updatedAt=1727261479893" 
                alt="Tasher Ghor Logo White"
                height={50} 
                width={50} 
                sx={{ mr: 1 }}
              />
            )}
          {/* <img
            src="https://tasherghor.com/wp-content/uploads/2021/07/tasherghor_white.png"
            height={50}
            width={50}
            sx={{ mr: 1 }}
            alt="Logo"
          /> */}

          <Label color="error" sx={{ ml: 2, fontSize: '20px' }}>
            তাসের ঘর
          </Label>
          <Box sx={{ flexGrow: 1 }} />
          {/* Header Menu for Desktop */}
          {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
          <Button
            variant="contained"
            target="_blank"
            rel="noopener"
            href="https://booking.tasherghor.com/booking/book-rooms-tasherghor"
            sx={{ mr: 1 }}
          >
            <Iconify icon={'material-symbols:shopping-cart-checkout'} sx={{mr: 1}}/>
            Book Now
          </Button>
          {/* Header Menu button for Mobile Devices - Menu Toggle Switch */}
          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}

