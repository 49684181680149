import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Avatar, Typography, IconButton, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Iconify from '../../components/Iconify';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Label } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(0),
    left: theme.spacing(0),
    zIndex: theme.zIndex.tooltip,
    width: '90%', // Adjust width as needed
    overflowX: 'hidden', // Disable horizontal scrolling
  },
  cardContainer: {
    display: 'flex', // Display cards in a row
    gap: theme.spacing(2), // Adjust gap between cards
    padding: theme.spacing(1),
    transform: 'translateX(0)', // Initialize translateX to 0
    transition: 'transform 1s ease-in-out', // Transition for smooth animation
  },
  card: {
    width: '450px', // Adjust width as needed
  },
  content: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'left',
    position: 'relative',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  reviewsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    maxWidth: '10%',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
}));

const reviews = [
  { 
    name: 'Rina Devi',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/Photo(23).jpg?updatedAt=1707784502276',
    content: 'Wow! Mousuni Island sounds like a hidden paradise! I cant wait to experience the...',
    longcontent: 'Wow! Mousuni Island sounds like a hidden paradise! I cant wait to experience the serene beaches and the simplicity of nature. Definitely adding this to my travel bucket list. 🏝️',
    rating: 5 
  },
  { 
    name: 'Arjun Kumar',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/Photo(22).jpg?updatedAt=1707784502954', 
    content: 'মৌসুনি দ্বীপে তারা দেখেছি, আমি কখনো এখানে যেতে পেরে খুশি! আমার বাংলার নিচু দিকে প্রাকৃতিক...', 
    longcontent: 'মৌসুনি দ্বীপে তারা দেখেছি, আমি কখনো এখানে যেতে পেরে খুশি! আমার বাংলার নিচু দিকে প্রাকৃতিক সৌন্দর্যের এই অদৃশ্য জগৎ অবশ্যই পর্যটন করা উচিত। 🌊',
    rating: 5
  },
  { 
    name: 'Priya Sharma',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/Photo(25).jpg?updatedAt=1707784502831', 
    content: 'The idea of staying in tents surrounded by natures beauty sounds amazing! Mousuni Island...', 
    longcontent: 'The idea of staying in tents surrounded by natures beauty sounds amazing! Mousuni Island seems like the perfect escape from the hustle and bustle of city life. Cant wait to disconnect and unwind there. 🌿',
    rating: 5
  },
  { 
    name: 'Rohan Patel',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/Photo(27).jpg?updatedAt=1707784502810', 
    content: 'Mousuni Islands blend of sea and riverside setting sounds magical! Its refreshing...',
    longcontent: 'Mousuni Islands blend of sea and riverside setting sounds magical! Its refreshing to see a place where technology takes a back seat and nature takes the lead. Cant wait to experience this slice of tranquility. 🌅',
    rating: 5
  },
  { 
    name: 'Rajesh Patel',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/profile_photo(21).jpg?updatedAt=1707783763625', 
    content: 'Thanks for sharing your experience! Its good to know about the shared bathroom and...', 
    longcontent: 'Thanks for sharing your experience! Its good to know about the shared bathroom and limited electricity situation beforehand. Planning my trip accordingly!', 
    rating: 5
  },
  { 
    name: 'Ananya Banerjee',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/profile_photo(20).jpg?updatedAt=1707783763524', 
    content: 'সেই নিরামিষ অভিজ্ঞতার জন্য আমি অত্যন্ত উত্সাহিত! ধন্যবাদ আপনি সম্পর্কে বিস্তারিত তথ্য...',
    longcontent: 'সেই নিরামিষ অভিজ্ঞতার জন্য আমি অত্যন্ত উত্সাহিত! ধন্যবাদ আপনি সম্পর্কে বিস্তারিত তথ্য শেয়ার করার জন্য।',
    rating: 5
  },
  { 
    name: 'Rohan Das',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/profile_photo(19).jpg?updatedAt=1707783763634', 
    content: 'মৌসুনি দ্বীপ শুনে আমি একটু অদ্ভুত অনুভব করছি! প্রকৃতির সাথে এক হস্তে যেতে গিয়ে কি আর...', 
    longcontent: 'মৌসুনি দ্বীপ শুনে আমি একটু অদ্ভুত অনুভব করছি! প্রকৃতির সাথে এক হস্তে যেতে গিয়ে কি আর অনুরোধ করা যায়?', 
    rating: 5
  },
  { 
    name: 'Priya Sharma',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/profile_photo(22).jpg?updatedAt=1707783763658', 
    content: 'Wow, Mousuni Island sounds like a hidden gem! Thanks for the heads-up about the...', 
    longcontent: 'Wow, Mousuni Island sounds like a hidden gem! Thanks for the heads-up about the shared facilities and no-frills camping. Cant wait to experience its untouched beauty!',
    rating: 5
  },
  { 
    name: 'Subhash Chatterjee',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/profile_photo(6).jpg?updatedAt=1707780249962', 
    content: 'আমি এই পোস্টের মাধ্যমে মৌসুনি দ্বীপে একটি সাজেশন পেয়েছি। এই সম্পূর্ণ অভিজ্ঞতার জন্য আমি...',
    longcontent: 'আমি এই পোস্টের মাধ্যমে মৌসুনি দ্বীপে একটি সাজেশন পেয়েছি। এই সম্পূর্ণ অভিজ্ঞতার জন্য আমি তা দেখতে চায়! 🏕️🌊',
    rating: 5
  },
  { 
    name: 'Arjun Sharma',
    profileUrl: 'https://ik.imagekit.io/wsoxey1dw/Logo/Profile%20Photo/profile_photo(3).jpg?updatedAt=1707780250075', 
    content: 'The village walking option is intriguing. Getting a glimpse into the lives of the...', 
    longcontent: 'The village walking option is intriguing. Getting a glimpse into the lives of the locals and how theyve adapted to the environment sounds fascinating. Cant wait to explore Mousuni Island! 🚶‍♂️👀',
    rating: 5
  },
  // Add more reviews as needed
];

const ReviewsGoogleWidget = () => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [visible, setVisible] = useState(true); // Define visible state here

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateX(-100); // Slide out current card
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex === reviews.length - 1 ? 0 : prevIndex + 1));
        setTranslateX(0); // Slide in next card
      }, 1000); // Adjust the delay to match the transition duration
    }, 6000); // Time to diplay the Review Card || Adjust the interval time

    return () => clearInterval(interval);
  }, []);

  const handleClose = () => {
    setVisible(false); // Set the visibility of the card to false
  };

  const handleOpen = () => {
    setVisible(true); // Set the visibility of the card to false
  };

  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating - fullStars >= 0.5;
    
    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={i} style={{ color: 'gold' }} />);
    }
    
    if (halfStar) {
      stars.push(<StarHalfIcon key={fullStars} style={{ color: 'gold' }} />);
    }
    
    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<StarBorderIcon key={fullStars + (halfStar ? 1 : 0) + i} style={{ color: 'silver' }} />);
    }
    
    return stars;
  };



  return (

    
    <div className={classes.root}>
      {visible && (
        <div className={classes.cardContainer} style={{ transform: `translateX(${translateX}%)` }}>
          <Card className={classes.card}>
            {/* .......................................................Top Right Bottom Left */}
            <CardContent className={classes.content} style={{ padding: '8px 8px 8px 10px' }}>
              <Avatar alt="Profile Pic" src={reviews[index].profileUrl} className={classes.avatar} />
              <div>
                <Typography variant="body1">{reviews[index].name}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>{reviews[index].content}</Typography>
                <Grid container direction="row" alignItems="center" pt={1}>
                  <Grid item mr={1}>
                    <Iconify icon={'logos:google-icon'} width={20} height={20} />
                  </Grid>
                  <Grid item style={{marginTop: '-8px'}} mr={2}>
                    <Typography variant="body1" color="text.disabled">Google</Typography>
                  </Grid>
                  <Grid item>
                    {renderStars(reviews[index].rating)}
                  </Grid>
                </Grid>
              </div>
              {/* Close icon */}
              <IconButton   className={classes.closeButton} onClick={handleClose}>
                {/* <Iconify icon={'carbon:close-filled'} width={20} height={20} /> */}
                <Iconify icon={'material-symbols:close'} width={20} height={20} />
              </IconButton>
            </CardContent>
          </Card>
          
        </div>
        
      )}
          <IconButton onClick={handleOpen} style={{ display: visible ? 'none' : 'inline-block', paddingBottom: '25px'}}>
          <Iconify icon={'logos:google-icon'} width={30} height={30} />
          </IconButton>
          
    </div>
  );
};
 
export default ReviewsGoogleWidget;
