// scroll bar
// import 'simplebar/src/simplebar.css';
import 'simplebar/dist/simplebar.min.css';


// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

//
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';

import ReactGA from 'react-ga'; // Import react-ga for Google Analytics

// Initialize Google Analytics
ReactGA.initialize('G-882F4N846D');

// Function to track page views
const trackPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// Custom CSS
// import 'assets/css/custom.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
       <SettingsProvider>
    <CollapseDrawerProvider>
      <BrowserRouter onUpdate={trackPageView}>
        <App />
      </BrowserRouter>
    </CollapseDrawerProvider>
  </SettingsProvider>
  </HelmetProvider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
