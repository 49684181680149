

function path(root, sublink) {
  return `${root}${sublink}`;
}

//const ROOTS_AUTH = '/auth';
const ROOTS_HOME = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {

};

export const PATH_HOME = {
  root: ROOTS_HOME,
  // dashboard: path(ROOTS_HOME, '/Mousuni⛺island'),
  dashboard: path(ROOTS_HOME, '/Home'),

  
};

export const PATH_PAGE = {
  // dashboard: '/Mousuni⛺island',
  Accommodations: '/Accommodations',
  reviews: '/Reviews',
  Testimonials: '/Testimonials',
  contact: '/Contact',
  gallery: '/Gallery',
  // blog: '/Blog',
  FAQs: '/FAQs',
  page404: '/404',
  page500: '/500',
  pageblank: '/blank',
  PrivacyPolicy: '/PrivacyPolicy',
  TermsConditions: '/Terms&Conditions',
  CancellationPolicy: '/CancellationPolicy',
  BlogPosts: '/Blog',
  FoodMenu: '/FoodMenu',
  FoodMenu2: '/FoodMenu2',


};

